import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import ThreeSixtyView from './threeSixtyView/threeSixtyView';
import FallBack from './fallBack/fallBack';

global.React = React;
global.ReactDOM = ReactDOM;
global.ThreeSixtyView = ThreeSixtyView;
global.FallBack = FallBack;
global.ReactDOMServer = ReactDOMServer;
