import React from 'react';
import './fallBack.scss';

export default class FallBack extends React.Component <{fallBackImageUrl: string}, {fallBackImageUrl: string}> {
    constructor(props) {
        super(props);

        this.state = {
            fallBackImageUrl: this.props.fallBackImageUrl
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined' && window.shell) {
            window.shell.subscribeTo('SCS.Update.FallBackImageUrl', (payload)=> {
                this.setState({
                    fallBackImageUrl: decodeURI(payload.newFallBackImageUrl)
                });
            }, 'SCS.FallBackImageUrl');
        }
    }

    public render() {
        return <div className={'scs_fallback_container'}>
            <img src={this.state.fallBackImageUrl} className={'fallback_img'}/>
        </div>
    }
}
